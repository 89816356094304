import { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
// cmps
import DrawerDialog from "../DrawerDialog";
import LightControls from "../LightControls";
import ColorPicker from "../ColorPicker";
// hooks
import useIsSmallScreen from "../../hooks/useIsSmallScreen";
// types
import type { EpDevice } from "../../types/device";
import type { ColorRGB } from "../../types/misc";

type Props = {
	open: boolean;
	color: ColorRGB;
	epDevice: EpDevice;
	onChange: (color: ColorRGB) => void;
	onClose: () => void;
};

const ColorSelector = ({ open, color, epDevice, onChange, onClose }: Props) => {
	const { t } = useTranslation();

	const isSmallScreen = useIsSmallScreen();

	const [colorRGB, setColorRGB] = useState(color);

	useEffect(() => {
		if (!open) {
			setColorRGB(color);
		}
	}, [open, color]);

	return (
		<DrawerDialog
			id="dlg-color-selector"
			title={t("rules.colorDialog")}
			open={open}
			disableSwipe={isSmallScreen}
			onClose={onClose}
			drawerActions={
				<Button
					className="btn-dlg-action-ok"
					variant="contained"
					disableElevation={true}
					onClick={() => (onChange(colorRGB))}
				>
					{t("dialog.save")}
				</Button>
			}
			dialogActions={
				<>
					<Button className="btn-dlg-action-close" color="inherit" onClick={onClose}>{t("dialog.close")}</Button>
					<Button
						className="btn-dlg-action-ok"
						variant="contained"
						disableElevation={true}
						onClick={() => (onChange(colorRGB))}
					>
						{t("dialog.save")}
					</Button>
				</>
			}
		>
			<LightControls color={colorRGB} epDevice={epDevice} />
			<ColorPicker outerLayoutHeight={400} color={colorRGB} onColorChange={setColorRGB} />
		</DrawerDialog>
	);
};

export default memo(ColorSelector);
